
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
const detecationItem = namespace("detecationItem");

@Component({})
export default class TestItemEdit extends Vue {
  @detecationItem.Action getDetecationItemDetail;
  emptyToLine = emptyToLine;
  get breadData() {
    return [
      { name: "养车日历项目", path: "/maintenance/detecationItem/list" },
      { name: "项目查看" }
    ];
  }

  propertyList: any = [{dataValue: '1', dataDesc: '正常'}, {dataValue: '2', dataDesc: '需要注意'}, {dataValue: '3', dataDesc: '需要更换'}];

  itemInfo: any = {};

  get projectCode() {
    let val = this.$route.params.projectCode;
    return (val || "");
  }

  loadItemDetail() {
    if (this.projectCode.length <= 0) {
      return;
    }

    this.getDetecationItemDetail({
      projectCode: this.projectCode
    }).then(data => {
      this.itemInfo = data.data;
    });
  }


  created() {
    this.init();
  }
  init() {
    this.loadItemDetail();
  }
}
